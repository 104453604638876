<template>
    <div v-loading="loading">
        <div class="main-title">会员设置</div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="100px" size="mini">
                <el-form-item label="名称: " label-position="left" prop="name" :rules="[
                  { required: true, message: '名称不能为空'},
                ]">
                    <el-input v-model="form.name" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="有效天数: " label-position="left" prop="days" :rules="[
                  { required: true, message: '有效天数'},
                ]">
                    <el-input-number v-model="form.days" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="价格: " label-position="left" prop="price" :rules="[
                  { required: true, message: '价格'},
                ]">
                    <el-input-number v-model="form.price" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="模块ID: " label-position="left" rop="module" :rules="[
                  { required: true, message: '模块ID'},
                ]">
                    <el-input-number v-model="form.module" :min="1"></el-input-number>
                </el-form-item>

                <el-form-item label="封面：" label-position="left" prop="link" :rules="[
                  { required: true, message: '封面'},
                ]">
                    <!-- <edit-cover  :uploadPath="uploadPath" @success="uploadSuccess">
                    </edit-cover> -->
                    <cover @success="uploadSuccess" :uploadPath="uploadPath"></cover>
                </el-form-item>

                <el-form-item label="内容：" label-position="left">
                    <editor @onChange="onChange" :content="form.detail"></editor>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="onSubmit" :loading="buttonLoading">
                        保存
                    </el-button>
                    <el-button style="margin-left: 10px" size="small" @click="$router.back()">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
// import editCover from '../../../components/editCover'
import Cover from "../../../components/cover";
import editor from '../../../components/editor'
export default {
    components: { Cover, editor },
    data() {
        return {
            loading: false,
            imageUrl: config.imageUrl,
            uploadPath: 'class',
            form: {
                name: '',
                link: '',
                detail: '',
                days: '',
                price: '',
                module: ''
            },
            buttonLoading: false
        }
    },
    methods: {
        ...mapActions('vipSet', ['audioBooksVipSet']),
        handleClick(e) {
            console.log(e);
        },
        uploadSuccess(path) {
            this.form.link = path
        },
        onChange(content) {
            this.form.detail = content
        },
        async onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.add()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async add() {
            this.buttonLoading = true
            const { data, res_info } = await this.audioBooksVipSet(this.form)
            this.buttonLoading = false
            if (res_info != 'ok') return
            this.$message.success('创建成功')
            this.$router.push('/vipSet')
        }
    },
    mounted() {

    },
}
</script>

<style scoped>

</style>
